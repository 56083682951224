import React from "react";

export default function Quote({quote}) {

    let quoteAuthor = (
        <div>
            <span className='quote-author'>{quote.frontmatter.author}</span>
            <span className='quote-source'>{quote.frontmatter.source}</span>
        </div>
    );

    if (quote.frontmatter.source_link) {
        quoteAuthor = (
            <a href={quote.frontmatter.source_link}>
                <span className='quote-author'>{quote.frontmatter.author}</span>
                <span className='quote-source'>{quote.frontmatter.source}</span>
            </a>
        )
    }

    return (
        <article
            className="quote-list-item"
            itemScope
            itemType="http://schema.org/Article"
        >
            <div className='quote-body' dangerouslySetInnerHTML={{
                __html: quote.html,
            }}
            />
            {quoteAuthor}
        </article>
    )
}
