import React from "react"
import {Link, graphql} from "gatsby"
import Quote from "../../components/quote";
import JSONData from "../../content/images.json";

const randomGenerator = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
};

const getRandomNode = (data) => {
    const nodes = data.nodes;
    const nodeId = randomGenerator(0, nodes.length);
    return nodes[nodeId];
};

const getImage = (filename, imageEdges) => {
    for (const image in imageEdges) {
        if (imageEdges[image].node.base === filename) {
            return imageEdges[image].node;
        }
    }
};

const RandomQuotePage = ({data}) => {
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
        setHasMounted(true);
    }, []);
    if (!hasMounted) {
        // Avoid mismatched re-rendering: https://www.joshwcomeau.com/react/the-perils-of-rehydration/
        return (
            <div/>
        );
    }

    const randomQuote = getRandomNode(data.quotes);

    const imageList = [];
    for (const image of JSONData.content) {
        if (image.categories && image.categories.includes('Textures')) {
            imageList.push(image);
        }
    }
    imageList.sort(() => Math.random() - 0.5);
    const randomImage = imageList[0];

    const image = getImage(randomImage.file, data.images.edges);

    let quoteLink = '';
    if(randomQuote.frontmatter) {
        quoteLink = (
            <Link className="quote-nav-link" to={randomQuote.frontmatter.slug}>This quote</Link>
        );
    }

    return (
        <div className="random-quote full-page" style={{backgroundImage: 'url(' + image.publicURL + ')'}}>
            <div className="quote-wrapper">
                <Quote quote={randomQuote}/>
                <div className="quote-nav">
                    <a className="quote-nav-link" href="/"><span role="img" aria-label="Home">🏠</span></a>
                    <a className="quote-nav-link" href="/quotes/random">Random quote</a>
                    {quoteLink}
                </div>
            </div>
        </div>
    )
};


export default RandomQuotePage;


export const pageQuery = graphql`
  query randomQuoteQuery {
    site {
      siteMetadata {
        title
      }
    }
    quotes: allMarkdownRemark(sort: {fields: [frontmatter___date], order: ASC}, 
    limit: 1000, 
    filter: {
        frontmatter: {contentType: {eq: "quote"}}, 
        html: {}
    }) {
      nodes {
        id
        frontmatter {
          title
          slug
          author
          source
          source_link
        }
        html
      }
    }
    images: allFile(filter: {relativeDirectory: {eq: "images"}, relativePath: {}}) {
        edges {
          node {
            id
            publicURL
            relativePath
            base
            childImageSharp {
                gatsbyImageData(
                  width: 1200
                  height: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
            }
          }
        }
    }
  }
`;

